.jstyling-select {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 0;
}

.jstyling-select-t {
  white-space: nowrap;
  overflow: hidden;
  width: 83%;
}

.jstyling-select-l {
  margin: 0;
  padding: 0;
  min-width: 100%;
  display: none;
  position: absolute;
  overflow-y: auto;
  z-index: 1000;
  max-width: 187px;
}

.jstyling-select-l div {
  list-style: none;
  display: block;
  overflow: hidden;
}

.jstyling-select.active {
  z-index: 700;
}

.jstyling-select.active .jstyling-select-l {
  display: block;
}

.jstyling-checkbox, .jstyling-radio {
  margin: 0;
  padding: 0;
  display: inline-block;
  z-index: 1;
}

.jstyling-checkbox input[type=checkbox], .jstyling-radio input[type=radio] {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  z-index: 0;
}

.jstyling-file {
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.jstyling-file-f {
  overflow: hidden;
  white-space: nowrap;
}

.jstyling-file-b {
  position: relative;
  overflow: hidden;
}

.jstyling-file input[type=file] {
  margin: 0;
  padding: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
}

.jstyling-select {
  width: 95%;
  cursor: pointer;
  -webkit-border-radius: 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px;
  -moz-background-clip: padding;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: #fff;
  color: #667078;
  font-size: 12px;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 2px rgba(0, 0, 0, .5);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 2px rgba(0, 0, 0, .5);
  box-shadow: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 2px rgba(0, 0, 0, .5);
  color: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 2px rgba(0, 0, 0, .5);
  behavior: url(/js/PIE.htc);
}

.jstyling-select.active .jstyling-select-s:before {
  background: #eff0f1
}

.jstyling-select-s {
  height: 25px;
  line-height: 22px;
}

.jstyling-select-s:before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  right: 4px;
  top: 3px;
  border-radius: 3px;
  background: rgb(228, 231, 232);
  background: -moz-linear-gradient(top, rgba(228, 231, 232, 1) 0%, rgba(190, 197, 199, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(228, 231, 232, 1) 0%, rgba(190, 197, 199, 1) 100%);
  background: linear-gradient(to bottom, rgba(228, 231, 232, 1) 0%, rgba(190, 197, 199, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e4e7e8', endColorstr='#bec5c7', GradientType=0);
  border-top: 1px solid rgba(255, 255, 255, .8);
  border-left: 1px solid rgba(233, 235, 237, .7);
  border-bottom: none;
  border-right: none;
  box-shadow: 1px 1px 1.36px 0px rgba(0, 0, 0, .4);
  z-index: 0
}

.jstyling-select-s:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 3.5px 0 3.5px;
  border-color: #5e5e5e transparent transparent transparent;
  z-index: 1;
  right: 10px;
  top: 11px;
}

.jstyling-select-s:hover:before {
  background: rgb(241, 242, 242);
  background: -moz-linear-gradient(top, rgba(241, 242, 242, 1) 0%, rgba(178, 186, 189, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(241, 242, 242, 1) 0%, rgba(178, 186, 189, 1) 100%);
  background: linear-gradient(to bottom, rgba(241, 242, 242, 1) 0%, rgba(178, 186, 189, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f2f2', endColorstr='#b2babd', GradientType=0);
}

.jstyling-select-s:hover:after {
  border-color: #24272a transparent transparent transparent;
}

.jstyling-select.disabled .jstyling-select-s:before {
  opacity: .7
}

.jstyling-select.disabled .jstyling-select-s:after {
  border-color: #939394 transparent transparent transparent;
}

.jstyling-select-t {
  padding: 2px 0 0 5px;
}

.jstyling-select-l {
  max-height: 300px;
  top: 25px;
  left: 0;
  background-color: #e5e8e9;
  background-image: -moz-linear-gradient(top, #e5e8e9 0%, #bbc3c5 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e5e8e9), color-stop(100%, #bbc3c5));
  background-image: -webkit-linear-gradient(top, #e5e8e9 0%, #bbc3c5 100%);
  background-image: -o-linear-gradient(top, #e5e8e9 0%, #bbc3c5 100%);
  background-image: -ms-linear-gradient(top, #e5e8e9 0%, #bbc3c5 100%);
  background-image: linear-gradient(to bottom, #e5e8e9 0%, #bbc3c5 100%);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .5), inset 0 1px 0 rgba(255, 255, 255, .6), inset 0 0 0 1px rgba(255, 255, 255, .14);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .5), inset 0 1px 0 rgba(255, 255, 255, .6), inset 0 0 0 1px rgba(255, 255, 255, .14);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .5), inset 0 1px 0 rgba(255, 255, 255, .6), inset 0 0 0 1px rgba(255, 255, 255, .14);
  color: 0 1px 2px rgba(0, 0, 0, .5), inset 0 1px 0 rgba(255, 255, 255, .6), inset 0 0 0 1px rgba(255, 255, 255, .14);
  -moz-border-bottom-left-radius: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-bottom-right-radius: 2px;
  border-bottom-right-radius: 2px;
  behavior: url(/js/PIE.htc);
}

.jstyling-select-l div {
  padding: 3px 4px;
  position: relative;
  border-bottom: 1px solid #bdc1c4;
}

.jstyling-select-l div:after, .jstyling-select-l div .after {
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #f7f7f7;
  position: absolute;
  left: 0;
  bottom: -2px;
}

.jstyling-select-l div:first-child:after, .jstyling-select-l div:first-child .after {
  background-color: none;
}

.jstyling-select-l div:hover {
  background: #d0d3d6;
}

.jstyling-select-l div.disabled {
  background: #E5E5E5;
  height: 5px;
  padding: 0;
}

.jstyling-select-l div.disabled:hover {
  color: #000000;
}

.jstyling-select-l div:first-child {
  border-top: 0;
}

.jstyling-select.active {
  background-position: 100% -28px;
}

.jstyling-select.disabled {
  background: #e4e4e4;
  color: #a4a9ac;
  cursor: default;
}

.jstyling-select.select_extra {
  background-color: #dee2e3;
  background-image: -moz-linear-gradient(top, #dee2e3 0%, #bec5c8 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #dee2e3), color-stop(100%, #bec5c8));
  background-image: -webkit-linear-gradient(top, #dee2e3 0%, #bec5c8 100%);
  background-image: -o-linear-gradient(top, #dee2e3 0%, #bec5c8 100%);
  background-image: -ms-linear-gradient(top, #dee2e3 0%, #bec5c8 100%);
  background-image: linear-gradient(to bottom, #dee2e3 0%, #bec5c8 100%);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .5), inset 0 1px 0 rgba(255, 255, 255, .6), inset 0 0 0 1px rgba(255, 255, 255, .14);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, .5), inset 0 1px 0 rgba(255, 255, 255, .6), inset 0 0 0 1px rgba(255, 255, 255, .14);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .5), inset 0 1px 0 rgba(255, 255, 255, .6), inset 0 0 0 1px rgba(255, 255, 255, .14);
  color: 0 1px 2px rgba(0, 0, 0, .5), inset 0 1px 0 rgba(255, 255, 255, .6), inset 0 0 0 1px rgba(255, 255, 255, .14);
  -pie-background: linear-gradient(#dee2e3, #bec5c8);
  behavior: url(/js/PIE.htc);
}

.jstyling-select.select_extra:hover {
  background-color: #ebeded;
  background-image: -moz-linear-gradient(top, #ebeded 0%, #b5bdc1 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ebeded), color-stop(100%, #b5bdc1));
  background-image: -webkit-linear-gradient(top, #ebeded 0%, #b5bdc1 100%);
  background-image: -o-linear-gradient(top, #ebeded 0%, #b5bdc1 100%);
  background-image: -ms-linear-gradient(top, #ebeded 0%, #b5bdc1 100%);
  background-image: linear-gradient(to bottom, #ebeded 0%, #b5bdc1 100%);
  -pie-background: linear-gradient(#ebeded, #b5bdc1);
  behavior: url(/js/PIE.htc);
}

.jstyling-select.select_extra.active {
  background-color: #eff1f1;
  background-image: -moz-linear-gradient(top, #eff1f1 0%, #eef0f0 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #eff1f1), color-stop(100%, #eef0f0));
  background-image: -webkit-linear-gradient(top, #eff1f1 0%, #eef0f0 100%);
  background-image: -o-linear-gradient(top, #eff1f1 0%, #eef0f0 100%);
  background-image: -ms-linear-gradient(top, #eff1f1 0%, #eef0f0 100%);
  background-image: linear-gradient(to bottom, #eff1f1 0%, #eef0f0 100%);
  -pie-background: linear-gradient(#eff1f1, #eef0f0);
  behavior: url(/js/PIE.htc);
}

.jstyling-checkbox {
  width: 16px;
  height: 16px;
  position: relative;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 2px rgba(0, 0, 0, .5);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 2px rgba(0, 0, 0, .5);
  box-shadow: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 2px rgba(0, 0, 0, .5);
  color: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 2px rgba(0, 0, 0, .5);
  background-color: #fff;
  -webkit-border-radius: 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px;
  -moz-background-clip: padding;
  border-radius: 2px;
  background-clip: padding-box;
  margin-right: 5px;
  top: -1px;
}

.jstyling-checkbox:before, .jstyling-checkbox .before {
  content: " ";
  width: 10px;
  height: 10px;
  position: absolute;
  left: 3px;
  top: 4px;
}

.jstyling-checkbox.active {
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 4px rgba(74, 41, 10, .59), inset 0 0 8px rgba(102, 112, 120, .09);
  -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 4px rgba(74, 41, 10, .59), inset 0 0 8px rgba(102, 112, 120, .09);
  box-shadow: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 4px rgba(74, 41, 10, .59), inset 0 0 8px rgba(102, 112, 120, .09);
  color: 0 1px 0 rgba(255, 255, 255, .35), inset 0 1px 4px rgba(74, 41, 10, .59), inset 0 0 8px rgba(102, 112, 120, .09);
  background-color: #E0BC0F;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9ImdyYWQtdWNnZy1nZW5lcmF0ZWQiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iMCUiIHkxPSIxMDAlIiB4Mj0iMCUiIHkyPSIwJSI+PHN0b3Agb2Zmc2V0PSIyMCUiIHN0b3AtY29sb3I9IiNmNmUyMTMiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2QwOWQwYSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPjwvc3ZnPg==);
  background-image: -moz-linear-gradient(bottom, #f6e213 20%, #d09d0a 100%);
  background-image: -webkit-linear-gradient(bottom, #f6e213 20%, #d09d0a 100%);
  background-image: -o-linear-gradient(bottom, #f6e213 20%, #d09d0a 100%);
  background-image: linear-gradient(bottom, #f6e213 20%, #d09d0a 100%);
  color: #603913;
  -webkit-border-radius: 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px;
  -moz-background-clip: padding;
  border-radius: 2px;
  background-clip: padding-box;
  -pie-background: linear-gradient(#f6e213, #d09d0a);
  behavior: url(/js/PIE.htc);
}

.jstyling-checkbox.active:before, .jstyling-checkbox.active .before {
  background: url(/images/default_gui.png) no-repeat 0 -450px;
}

.jstyling-checkbox.disabled {
  background-position: 100% -32px;
}

.jstyling-checkbox.active.disabled {
  background-position: 100% -48px;
}

.jstyling-radio {
  width: 16px;
  height: 16px;
  background-position: 100% -64px;
  vertical-align: middle;
}

.jstyling-radio.active {
  background-position: 100% -80px;
}

.jstyling-radio.disabled {
  background-position: 100% -96px;
}

.jstyling-radio.active.disabled {
  background-position: 100% -112px;
}

.jstyling-file-f {
  padding: 2px 2px;
  width: 200px;
  float: left;
  border: 1px solid #ccc;
  height: 20px;
}

.jstyling-file-b {
  margin-left: 4px;
  padding: 2px 2px;
  width: 80px;
  float: left;
  border: 1px solid #ccc;
  height: 20px;
  background: #eee;
  text-align: center;
}