.card-page__card-page-top {
  padding: 15px 15px 0;
  width: calc(100% - 30px)
}

.card-page__card-page-top h1 {
  line-height: 30px
}

.card-page-top__card-images-accessories-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: top;
  justify-content: space-around
}

.card-images-accessories-wrapper__card-images-previews-wrapper {
  position: relative;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 10px;
  width: 650px;
  overflow: hidden;
  padding-bottom: calc(77% + 66px);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  clear: both
}

.card-images-accessories-wrapper__card-images-previews-wrapper.single-image .card-images-previews-wrapper__card-previews-floater {
  display: none
}

.card-images-accessories-wrapper__card-images-previews-wrapper.single-image {
  padding-bottom: 77%
}

.card-images-accessories-wrapper__card-images-previews-wrapper.single-image .card-images-previews-wrapper__card-images-floater {
  height: 100%
}

.card-images-previews-wrapper__card-images-floater {
  position: absolute;
  top: 0;
  height: 487px;
  left: 0;
  -webkit-transition: left .5s ease;
  -moz-transition: left .5s ease;
  transition: left .5s ease
}

.card-images-floater__card-image-item {
  position: relative;
  height: 100%;
  background: none no-repeat center center /contain;
  float: left
}

.card-images-previews-wrapper__card-previews-floater {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 66px;
  cursor: pointer;
  -webkit-transition: left .5s ease;
  -moz-transition: left .5s ease;
  transition: left .5s ease
}

.card-previews-floater__card-preview-item {
  position: relative;
  width: 62px;
  height: 62px;
  background: none no-repeat center center /cover;
  float: left;
  margin: 2px
}

.card-previews-floater__card-preview-item.selected {
  border: 2px solid#00afea;
  margin: 0
}

.card-images-previews-wrapper__navigate-button {
  position: absolute;
  width: 50%;
  height: 487px;
  z-index: 1;
  cursor: pointer
}


.card-images-previews-wrapper__navigate-button:before {
  position: absolute;
  content: "";
  width: 11px;
  height: 18px;
  background: url(/images/default_gui.png) no-repeat -14px -29px;
  top: 50%;
  opacity: .6;
  margin-top: -9px
}

.card-images-previews-wrapper__navigate-button.prev:before {
  left: -20px;
  -webkit-transition: left .5s ease;
  -moz-transition: left .5s ease;
  transition: left .5s ease
}

.card-images-previews-wrapper__navigate-button.next:before {
  right: -20px;
  -webkit-transition: right .5s ease;
  -moz-transition: right .5s ease;
  transition: right .5s ease
}

.card-images-accessories-wrapper__card-images-previews-wrapper:not(.single-image):hover>.card-images-previews-wrapper__navigate-button.prev:before {
  left: 10px
}

.card-images-accessories-wrapper__card-images-previews-wrapper:not(.single-image):hover>.card-images-previews-wrapper__navigate-button.next:before {
  right: 10px
}

.card-images-previews-wrapper__navigate-button.prev {
  left: 0
}

.card-images-previews-wrapper__navigate-button.next:before {
  transform: rotate(180deg)
}

.card-images-previews-wrapper__navigate-button.next {
  right: 0
}

.card-accessory-item__image {
  position: relative;
  width: 70px;
  height: 50px;
  background-position: center 4px;
  background-size: contain;
  background-repeat: no-repeat;
  float: left
}

.card-accessory-item__description {
  position: relative;
  width: calc(100% - 80px);
  margin-left: 10px;
  min-height: 50px;
  float: left;
  overflow: hidden
}

.card-accessory-item__description>div:first-of-type {
  position: relative;
  color: #000;
  font-size: 11px;
  line-height: 16px
}

.card-accessory-item__description>div:last-of-type {
  position: relative;
  color: #a11437;
  font-size: 14px;
  font-weight: bold
}

.card-page-top__touch-info {
  display: none;
  padding-bottom: 20px
}

.card-page-top__touch-info>div {
  display: table-row
}

.card-page-top__touch-info>div>div:nth-child(1) {
  width: 38%;
}

.card-page-top__touch-info>div>div {
  display: table-cell;
  width: 50%;
  color: #000;
  padding: 5px 0
}

.card-page-top__touch-info>div>div:first-of-type {
  color: #999
}

.card-page-top__touch-info>div:last-of-type>div {
  color: #000 !important;
  font-size: 16px !important;
  vertical-align: bottom
}

.card-page-top__touch-info>div:last-of-type>div:first-of-type>div:nth-of-type(2) {
  text-decoration: line-through !important
}

.card-page-top__card-page-details {
  position: relative;
  display: table;
  width: 779px;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0,0,0,0.45);
  background: #e3e6e7;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2UzZTZlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNiY2MzYzYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,rgba(227,230,231,1) 0,rgba(188,195,198,1) 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0,rgba(227,230,231,1)),color-stop(100%,rgba(188,195,198,1)));
  background: -webkit-linear-gradient(top,rgba(227,230,231,1) 0,rgba(188,195,198,1) 100%);
  background: -o-linear-gradient(top,rgba(227,230,231,1) 0,rgba(188,195,198,1) 100%);
  background: -ms-linear-gradient(top,rgba(227,230,231,1) 0,rgba(188,195,198,1) 100%);
  background: linear-gradient(to bottom,rgba(227,230,231,1) 0,rgba(188,195,198,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e3e6e7',endColorstr='#bcc3c6',GradientType=0);
  margin-top: 6px;
  overflow: hidden
}

.card-page-details__details-container {
  position: relative;
  float: left;
  width: 500px;
  margin: 4px 0 0 9px
}

.card-page-details__details-container.disabled {
  width: calc(100% - 12px);
  margin: 5px;
}

.details-container__details-item {
  position: relative;
  height: 28px;
  line-height: 28px;
  color: #667078;
  font-size: 12px;
  border-top: 1px solid #e0e4e5;
  border-bottom: 1px solid #a8afb3;
  padding-left: 5px;
  font-weight: bold
}

.disabled-car_label {
  text-align: center;
  margin-top: 26px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.details-container__details-item>span:last-child {
  float: right;
  color: #004b72;
  font-weight: normal;
  font-size: 14px
}

.details-container__details-item:first-child {
  border-top: 0
}

.details-container__details-item:last-child {
  border-bottom: 0
}

.card-page-details__price-container {
  display: table-cell;
  vertical-align: middle;
  width: 231px;
  height: 100%;
  padding: 0 14px;
  background: #50575e url("/images/car_info_price_bg.png") left bottom repeat-x;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.price-container__price-value {
  color: white;
  font-weight: bold;
  font-size: 18px;
  float: left;
  padding-right: 20px
}

.price-container__price-value>div:nth-of-type(3)>span:first-of-type {
  text-decoration: line-through !important;
  font-size: 17px
}

.price-value__descr {
  color: #c0c7ca;
  font-weight: bold;
  font-size: 15px
}

.card-page__card-page-tabs {
  position: relative;
  box-shadow: 0 -1px 2px rgba(0,0,0,0.21),0 0 0 #fff inset;
  background: #d0d5d7;
  z-index: 0;
  padding: 8px 20px 16px 20px
}

.card-page__card-page-tabs:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 95px;
  left: 0;
  top: 0;
  background: #d0d5d7;
  background: #fff;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNkMGQ1ZDciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,rgba(255,255,255,1) 0,rgba(208,213,215,1) 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0,rgba(255,255,255,1)),color-stop(100%,rgba(208,213,215,1)));
  background: -webkit-linear-gradient(top,rgba(255,255,255,1) 0,rgba(208,213,215,1) 100%);
  background: -o-linear-gradient(top,rgba(255,255,255,1) 0,rgba(208,213,215,1) 100%);
  background: -ms-linear-gradient(top,rgba(255,255,255,1) 0,rgba(208,213,215,1) 100%);
  background: linear-gradient(to bottom,rgba(255,255,255,1) 0,rgba(208,213,215,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#d0d5d7',GradientType=0);
  z-index: -1
}

ul.card-page-tabs__card-tabs-container {
  -webkit-box-shadow: 0 1px 0 rgba(255,255,255,0.35),inset 0 1px 2px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 1px 0 rgba(255,255,255,0.35),inset 0 1px 2px rgba(0,0,0,0.2);
  box-shadow: 0 1px 0 rgba(255,255,255,0.35),inset 0 1px 2px rgba(0,0,0,0.2);
  color: 0 1px 0 rgba(255,255,255,0.35),inset 0 1px 2px rgba(0,0,0,0.2);
  display: inline-block;
  vertical-align: top;
  padding: 4px;
  -webkit-border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 4px;
  -moz-background-clip: padding;
  border-radius: 4px;
  background-clip: padding-box;
  list-style-type: none;
  margin-bottom: 20px
}

li.card-tabs-container__card-tab-item {
  box-shadow: 0 1px 2px rgba(0,0,0,0.5),0 -1px 0 rgba(255,255,255,0.2) inset,0 0 0 1px rgba(255,255,255,0.14) inset;
  background-color: #e3e6e7;
  background: linear-gradient(to bottom,#e3e6e7 0,#bcc3c6 100%);
  display: block;
  float: left;
  vertical-align: top;
  border-radius: 3px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  outline: medium none !important;
  color: #667078;
  text-shadow: 0 1px 0 rgba(255,255,255,0.7);
  text-align: center;
  cursor: pointer
}

li.card-tabs-container__card-tab-item.big {
  width: 195px;
  height: 33px;
  font-size: 18px;
  line-height: 33px
}

li.card-tabs-container__card-tab-item.small {
  width: 85px;
  height: 24px;
  font-size: 14px;
  line-height: 24px
}

li.card-tabs-container__card-tab-item:hover {
  color: #de7607
}

li.card-tabs-container__card-tab-item.selected {
  box-shadow: 0 1px 0 rgba(255,255,255,.35),0 1px 4px rgba(74,41,10,.59)inset,0 0 8px rgba(102,112,120,.09)inset;
  background: #f6e213;
  background: -moz-linear-gradient(top,#f6e213 0,#d09d0a 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0,#f6e213),color-stop(100%,#d09d0a));
  background: -webkit-linear-gradient(top,#f6e213 0,#d09d0a 100%);
  background: -o-linear-gradient(top,#f6e213 0,#d09d0a 100%);
  background: -ms-linear-gradient(top,#f6e213 0,#d09d0a 100%);
  background: linear-gradient(to bottom,#f6e213 0,#d09d0a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6e213',endColorstr='#d09d0a',GradientType=0);
  color: #603913;
  border-radius: 2px;
  background-clip: padding-box
}

li.card-tabs-container__card-tab-item:first-child {
  -webkit-border-top-left-radius: 4px;
  -moz-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-top-right-radius: 0;
  -moz-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -moz-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0
}

li.card-tabs-container__card-tab-item:last-child {
  -webkit-border-top-right-radius: 4px;
  -moz-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-border-top-left-radius: 0;
  -moz-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0
}

ul.card-page-tabs__card-content-container {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0
}

li.card-content-container__card-content-item {
  display: none;
  position: relative;
  width: 99%;
  overflow: hidden
}

li.card-content-container__card-content-item.selected {
  display: block
}

li.card-content-container__card-content-item>ul.card-page-tabs__card-tabs-container {
  margin-left: 10px
}

li.card-content-container__card-content-item>ul.card-page-tabs__card-content-container>li.card-content-container__card-content-item {
  margin-left: 10px
}

li.card-content-container__card-content-item:nth-of-type(3)>.card-content-pad__car-pad-title:after {
  display: none
}

.card-content-item__card-content-bar {
  position: relative;
  width: 350px;
  float: left;
  overflow: hidden;
  padding-right: 18px
}

.card-content-item__card-content-bar>div {
  border-bottom: 1px solid #bfc3c6;
  margin: 0 0 13px 5px
}

.card-page-tabs__options-legend {
  position: relative;
  float: right;
  padding-top: 24px
}

.card-page-tabs__options-legend > .wordBtn {
  width: 35px;
  height: 35px;
  background-size: contain;
  position: absolute;
  left: -48px;
  top: 16px;
  background: url("/images/wordApp.svg");
  background-size: contain;
  cursor: pointer;
}

.card-page-tabs__options-legend>div {
  display: inline-block
}

.card-content-item__card-content-line {
  position: relative;
  display: table;
  width: 740px;
  padding: 10px 0;
  margin-left: 15px;
  border-bottom: 1px solid #bdc1c4
}

.card-content-item__card-content-line>div {
  display: table-cell;
  text-align: left;
  width: 50%
}

.card-content-item__card-content-line:last-child {
  border-bottom: 0;
  padding-bottom: 8px
}

.card-content-item__card-content-line>div:first-child {
  font-size: 14px;
  font-weight: bold;
  width: 370px;
  float: left;
  padding-left: 20px;
  padding-right: 30px
}

.card-content-bar__card-content-pad {
  color: #3a3f44;
  padding-bottom: 10px
}

.card-content-pad__car-pad-title {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  padding: 0 0 10px 9px;
  cursor: pointer
}

.card-content-pad__car-pad-title:not(.interior):after {
  position: absolute;
  content: "";
  width: 9px;
  height: 14px;
  right: 0;
  top: 4px;
  transform: rotate(-90deg);
  background: url(/images/default_gui.png) no-repeat -17px 0
}

.card-content-pad__car-pad-title.closed:not(.interior):after {
  transform: rotate(-90deg)
}

.card-content-pad__car-pad-title.opened:not(.interior):after {
  transform: rotate(90deg)
}

.card-content-pad__car-pad-title.opened ~ .card-content-bar__card-content-pad {
  display: block
}

.card-content-pad__car-pad-title.closed ~ .card-content-bar__card-content-pad {
  display: none
}

.card-content-pad__car-pad-title.interior {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.card-content-pad__car-pad-title.interior span {
  white-space: nowrap;
  position: relative;
  padding-right: 11px
}

.card-content-pad__car-pad-title.interior span:after {
  position: absolute;
  content: '';
  height: 15px;
  top: 3px;
  right: 4px;
  border-right: 2px solid #3a3f45
}

.card-content-pad__car-pad-title.interior span:last-child:after {
  display: none
}

.card-content-pad__car-pad-item {
  position: relative;
  margin: 0 0 10px 15px;
  padding: 0 0 0 15px;
  list-style: none outside none;
  font-weight: normal;
  font-size: 13px
}

.card-content-pad__car-pad-item:before {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  box-shadow: 0 1px 0 0 #666869 inset,0 1px 1px 0 #ededed;
  content: " ";
  display: inline-block;
  margin: 0 10px 1px -20px;
  background: none repeat scroll 0 0 #a7a9ab
}

.card-content-pad__car-pad-item.additional:before {
  background: none repeat scroll 0 0 #f2bd0c
}

.card-content-item__additinal-description {
  position: relative;
  margin: 20px 0 9px 15px;
  padding: 20px 0 0 25px;
  width: 715px;
  border-top: 1px solid #bfc3c6
}

.card-content-item__closeButtonContainer {
  position: relative;
  margin: 20px 0 9px 15px;
  padding: 20px 0 0 25px;
  width: 715px;
  border-top: 1px solid #bfc3c6
}

.card-content-item__additinal-description:before {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  box-shadow: 0 1px 0 0 #666869 inset,0 1px 1px 0 #ededed;
  content: " ";
  display: inline-block;
  margin: 0 10px 1px -20px;
  background: none repeat scroll 0 0 #f2bd0c
}

.fotorama__nav.fotorama__nav--thumbs {
  padding-top: 10px
}

.result-cell__credit-link {
  text-decoration: underline;
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  color: #004b90;
  outline: medium none !important;
  cursor: pointer;
  margin: 5px 10px
}

.result-cell__credit-link:hover {
  color: #de7607;
  text-decoration: underline
}

.card-page-details__credit-link {
  display: block;
  position: relative;
  float: right;
  padding: 15px 25px 15px 15px;
  font-size: 14px;
  color: #004b90;
  text-decoration: underline;
  font-weight: normal;
  border-radius: 4px;
  background: #e3e6e7;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2UzZTZlNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNiY2MzYzYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,rgba(227,230,231,1) 0,rgba(188,195,198,1) 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0,rgba(227,230,231,1)),color-stop(100%,rgba(188,195,198,1)));
  background: -webkit-linear-gradient(top,rgba(227,230,231,1) 0,rgba(188,195,198,1) 100%);
  background: -o-linear-gradient(top,rgba(227,230,231,1) 0,rgba(188,195,198,1) 100%);
  background: -ms-linear-gradient(top,rgba(227,230,231,1) 0,rgba(188,195,198,1) 100%);
  background: linear-gradient(to bottom,rgba(227,230,231,1) 0,rgba(188,195,198,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e3e6e7',endColorstr='#bcc3c6',GradientType=0);
  margin: 40px 82px 0 0
}

.card-page-details__credit-link:hover {
  color: #de7607;
  text-decoration: underline
}

.card-page-details__credit-link:before {
  position: absolute;
  content: "ÐžÐ½Ð»Ð°Ð¹Ð½";
  text-align: center;
  color: #fff;
  font-size: 11px;
  line-height: 125px;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: 3px solid #fff;
  right: -75px;
  top: -18px;
  background: #27a82a
}

.card-page-details__credit-link:after {
  position: absolute;
  font-family: ilsaRegular;
  content: "P";
  color: #FFF;
  font-size: 29px;
  text-align: center;
  line-height: 42px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 3px solid #FFF;
  right: -57px;
  top: -10px;
  background: #27a82a none repeat scroll 0 0;
  padding-left: 3px;
  padding-bottom: 3px
}

.credit-page__credit-form {
  position: relative;
  margin: 0 auto
}

.credit-form__iframe-container {
  position: relative;
  border: 0;
  width: 100%
}

.jstyling-select {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 0
}

.jstyling-select-t {
  white-space: nowrap;
  overflow: hidden;
  width: 83%
}

.jstyling-select-l {
  margin: 0;
  padding: 0;
  min-width: 100%;
  display: none;
  position: absolute;
  overflow-y: auto;
  z-index: 1000;
  max-width: 187px
}

.jstyling-select-l div {
  list-style: none;
  display: block;
  overflow: hidden
}

.jstyling-select.active {
  z-index: 700
}

.jstyling-select.active .jstyling-select-l {
  display: block
}

.jstyling-checkbox,.jstyling-radio {
  margin: 0;
  padding: 0;
  display: inline-block;
  z-index: 1
}

.jstyling-checkbox input[type=checkbox],.jstyling-radio input[type=radio] {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  z-index: 0
}

.jstyling-file {
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative
}

.jstyling-file-f {
  overflow: hidden;
  white-space: nowrap
}

.jstyling-file-b {
  position: relative;
  overflow: hidden
}

.jstyling-file input[type=file] {
  margin: 0;
  padding: 0;
  opacity: 0;
  filter: alpha(opacity = 0);
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0
}

.jstyling-select {
  width: 95%;
  cursor: pointer;
  -webkit-border-radius: 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px;
  -moz-background-clip: padding;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: #fff;
  color: #667078;
  font-size: 12px;
  -webkit-box-shadow: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 2px rgba(0,0,0,.5);
  -moz-box-shadow: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 2px rgba(0,0,0,.5);
  box-shadow: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 2px rgba(0,0,0,.5);
  color: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 2px rgba(0,0,0,.5);
  behavior: url(/js/PIE.htc)
}

.jstyling-select.active .jstyling-select-s:before {
  background: #eff0f1
}

.jstyling-select-s {
  height: 25px;
  line-height: 22px
}

.jstyling-select-s:before {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  right: 4px;
  top: 3px;
  border-radius: 3px;
  background: #e4e7e8;
  background: -moz-linear-gradient(top,rgba(228,231,232,1) 0,rgba(190,197,199,1) 100%);
  background: -webkit-linear-gradient(top,rgba(228,231,232,1) 0,rgba(190,197,199,1) 100%);
  background: linear-gradient(to bottom,rgba(228,231,232,1) 0,rgba(190,197,199,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e4e7e8',endColorstr='#bec5c7',GradientType=0);
  border-top: 1px solid rgba(255,255,255,.8);
  border-left: 1px solid rgba(233,235,237,.7);
  border-bottom: 0;
  border-right: 0;
  box-shadow: 1px 1px 1.36px 0 rgba(0,0,0,.4);
  z-index: 0
}

.jstyling-select-s:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 3.5px 0 3.5px;
  border-color: #5e5e5e transparent transparent transparent;
  z-index: 1;
  right: 10px;
  top: 11px
}

.jstyling-select-s:hover:before {
  background: #f1f2f2;
  background: -moz-linear-gradient(top,rgba(241,242,242,1) 0,rgba(178,186,189,1) 100%);
  background: -webkit-linear-gradient(top,rgba(241,242,242,1) 0,rgba(178,186,189,1) 100%);
  background: linear-gradient(to bottom,rgba(241,242,242,1) 0,rgba(178,186,189,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f2f2',endColorstr='#b2babd',GradientType=0)
}

.jstyling-select-s:hover:after {
  border-color: #24272a transparent transparent transparent
}

.jstyling-select.disabled .jstyling-select-s:before {
  opacity: .7
}

.jstyling-select.disabled .jstyling-select-s:after {
  border-color: #939394 transparent transparent transparent
}

.jstyling-select-t {
  padding: 2px 0 0 5px
}

.jstyling-select-l {
  max-height: 300px;
  top: 25px;
  left: 0;
  background-color: #e5e8e9;
  background-image: -moz-linear-gradient(top,#e5e8e9 0,#bbc3c5 100%);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#e5e8e9),color-stop(100%,#bbc3c5));
  background-image: -webkit-linear-gradient(top,#e5e8e9 0,#bbc3c5 100%);
  background-image: -o-linear-gradient(top,#e5e8e9 0,#bbc3c5 100%);
  background-image: -ms-linear-gradient(top,#e5e8e9 0,#bbc3c5 100%);
  background-image: linear-gradient(to bottom,#e5e8e9 0,#bbc3c5 100%);
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.5),inset 0 1px 0 rgba(255,255,255,.6),inset 0 0 0 1px rgba(255,255,255,.14);
  -moz-box-shadow: 0 1px 2px rgba(0,0,0,.5),inset 0 1px 0 rgba(255,255,255,.6),inset 0 0 0 1px rgba(255,255,255,.14);
  box-shadow: 0 1px 2px rgba(0,0,0,.5),inset 0 1px 0 rgba(255,255,255,.6),inset 0 0 0 1px rgba(255,255,255,.14);
  color: 0 1px 2px rgba(0,0,0,.5),inset 0 1px 0 rgba(255,255,255,.6),inset 0 0 0 1px rgba(255,255,255,.14);
  -moz-border-bottom-left-radius: 2px;
  border-bottom-left-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-bottom-right-radius: 2px;
  border-bottom-right-radius: 2px;
  behavior: url(/js/PIE.htc)
}

.jstyling-select-l div {
  padding: 3px 4px;
  position: relative;
  border-bottom: 1px solid #bdc1c4
}

.jstyling-select-l div:after,.jstyling-select-l div .after {
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #f7f7f7;
  position: absolute;
  left: 0;
  bottom: -2px
}

.jstyling-select-l div:first-child:after,.jstyling-select-l div:first-child .after {
  background-color: none
}

.jstyling-select-l div:hover {
  background: #d0d3d6
}

.jstyling-select-l div.disabled {
  background: #e5e5e5;
  height: 5px;
  padding: 0
}

.jstyling-select-l div.disabled:hover {
  color: #000
}

.jstyling-select-l div:first-child {
  border-top: 0
}

.jstyling-select.active {
  background-position: 100% -28px
}

.jstyling-select.disabled {
  background: #e4e4e4;
  color: #a4a9ac;
  cursor: default
}

.jstyling-select.select_extra {
  background-color: #dee2e3;
  background-image: -moz-linear-gradient(top,#dee2e3 0,#bec5c8 100%);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#dee2e3),color-stop(100%,#bec5c8));
  background-image: -webkit-linear-gradient(top,#dee2e3 0,#bec5c8 100%);
  background-image: -o-linear-gradient(top,#dee2e3 0,#bec5c8 100%);
  background-image: -ms-linear-gradient(top,#dee2e3 0,#bec5c8 100%);
  background-image: linear-gradient(to bottom,#dee2e3 0,#bec5c8 100%);
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.5),inset 0 1px 0 rgba(255,255,255,.6),inset 0 0 0 1px rgba(255,255,255,.14);
  -moz-box-shadow: 0 1px 2px rgba(0,0,0,.5),inset 0 1px 0 rgba(255,255,255,.6),inset 0 0 0 1px rgba(255,255,255,.14);
  box-shadow: 0 1px 2px rgba(0,0,0,.5),inset 0 1px 0 rgba(255,255,255,.6),inset 0 0 0 1px rgba(255,255,255,.14);
  color: 0 1px 2px rgba(0,0,0,.5),inset 0 1px 0 rgba(255,255,255,.6),inset 0 0 0 1px rgba(255,255,255,.14);
  -pie-background: linear-gradient(#dee2e3,#bec5c8);
  behavior: url(/js/PIE.htc)
}

.jstyling-select.select_extra:hover {
  background-color: #ebeded;
  background-image: -moz-linear-gradient(top,#ebeded 0,#b5bdc1 100%);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#ebeded),color-stop(100%,#b5bdc1));
  background-image: -webkit-linear-gradient(top,#ebeded 0,#b5bdc1 100%);
  background-image: -o-linear-gradient(top,#ebeded 0,#b5bdc1 100%);
  background-image: -ms-linear-gradient(top,#ebeded 0,#b5bdc1 100%);
  background-image: linear-gradient(to bottom,#ebeded 0,#b5bdc1 100%);
  -pie-background: linear-gradient(#ebeded,#b5bdc1);
  behavior: url(/js/PIE.htc)
}

.jstyling-select.select_extra.active {
  background-color: #eff1f1;
  background-image: -moz-linear-gradient(top,#eff1f1 0,#eef0f0 100%);
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#eff1f1),color-stop(100%,#eef0f0));
  background-image: -webkit-linear-gradient(top,#eff1f1 0,#eef0f0 100%);
  background-image: -o-linear-gradient(top,#eff1f1 0,#eef0f0 100%);
  background-image: -ms-linear-gradient(top,#eff1f1 0,#eef0f0 100%);
  background-image: linear-gradient(to bottom,#eff1f1 0,#eef0f0 100%);
  -pie-background: linear-gradient(#eff1f1,#eef0f0);
  behavior: url(/js/PIE.htc)
}

.jstyling-checkbox {
  width: 16px;
  height: 16px;
  position: relative;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 2px rgba(0,0,0,.5);
  -moz-box-shadow: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 2px rgba(0,0,0,.5);
  box-shadow: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 2px rgba(0,0,0,.5);
  color: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 2px rgba(0,0,0,.5);
  background-color: #fff;
  -webkit-border-radius: 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px;
  -moz-background-clip: padding;
  border-radius: 2px;
  background-clip: padding-box;
  margin-right: 5px;
  top: -1px
}

.jstyling-checkbox:before,.jstyling-checkbox .before {
  content: " ";
  width: 10px;
  height: 10px;
  position: absolute;
  left: 3px;
  top: 4px
}

.jstyling-checkbox.active {
  -webkit-box-shadow: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 4px rgba(74,41,10,.59),inset 0 0 8px rgba(102,112,120,.09);
  -moz-box-shadow: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 4px rgba(74,41,10,.59),inset 0 0 8px rgba(102,112,120,.09);
  box-shadow: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 4px rgba(74,41,10,.59),inset 0 0 8px rgba(102,112,120,.09);
  color: 0 1px 0 rgba(255,255,255,.35),inset 0 1px 4px rgba(74,41,10,.59),inset 0 0 8px rgba(102,112,120,.09);
  background-color: #e0bc0f;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9ImdyYWQtdWNnZy1nZW5lcmF0ZWQiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4MT0iMCUiIHkxPSIxMDAlIiB4Mj0iMCUiIHkyPSIwJSI+PHN0b3Agb2Zmc2V0PSIyMCUiIHN0b3AtY29sb3I9IiNmNmUyMTMiIHN0b3Atb3BhY2l0eT0iMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2QwOWQwYSIgc3RvcC1vcGFjaXR5PSIxIi8+PC9saW5lYXJHcmFkaWVudD48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPjwvc3ZnPg==);
  background-image: -moz-linear-gradient(bottom,#f6e213 20%,#d09d0a 100%);
  background-image: -webkit-linear-gradient(bottom,#f6e213 20%,#d09d0a 100%);
  background-image: -o-linear-gradient(bottom,#f6e213 20%,#d09d0a 100%);
  background-image: linear-gradient(bottom,#f6e213 20%,#d09d0a 100%);
  color: #603913;
  -webkit-border-radius: 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px;
  -moz-background-clip: padding;
  border-radius: 2px;
  background-clip: padding-box;
  -pie-background: linear-gradient(#f6e213,#d09d0a);
  behavior: url(/js/PIE.htc)
}

.jstyling-checkbox.active:before,.jstyling-checkbox.active .before {
  background: url(/images/default_gui.png) no-repeat 0 -450px
}

.jstyling-checkbox.disabled {
  background-position: 100% -32px
}

.jstyling-checkbox.active.disabled {
  background-position: 100% -48px
}

.jstyling-radio {
  width: 16px;
  height: 16px;
  background-position: 100% -64px;
  vertical-align: middle
}

.jstyling-radio.active {
  background-position: 100% -80px
}

.jstyling-radio.disabled {
  background-position: 100% -96px
}

.jstyling-radio.active.disabled {
  background-position: 100% -112px
}

.jstyling-file-f {
  padding: 2px 2px;
  width: 200px;
  float: left;
  border: 1px solid #ccc;
  height: 20px
}

.jstyling-file-b {
  margin-left: 4px;
  padding: 2px 2px;
  width: 80px;
  float: left;
  border: 1px solid #ccc;
  height: 20px;
  background: #eee;
  text-align: center
}

.offer-page__offer-form {
  position: relative;
  width: 670px;
  margin: 0 auto;
  padding: 30px 10px
}

.offer-form__form-title {
  font-size: 15px;
  line-height: 30px
}

.offer-form__form-value {
  font-weight: bold;
  font-size: 15px;
  line-height: 30px;
  padding-bottom: 20px
}

.offer-form__form-value.markup div span,.offer-form__form-value.markup span:nth-of-type(1) {
  color: #c61633
}

.offer-form__form-title.price>.offer-form__form-value:first-of-type {
  padding-bottom: 0 !important
}

.offer-form__form-title.price>.offer-form__form-value:nth-of-type(2) {
  padding-bottom: 0 !important
}

.offer-form__form-title.price>.offer-form__form-value:nth-of-type(2)>span {
  text-decoration: line-through
}

.rouble.to-offer:after {
  bottom: -10px
}

.offer-form__form-control {
  background-color: #f8f9f9;
  box-shadow: 0 1px 0 rgba(255,255,255,0.35),0 1px 2px rgba(0,0,0,0.5) inset;
  border-radius: 5px 5px 5px 5px;
  font-size: 13px;
  padding: 0 6px;
  border: 0
}

.offer-form__form-input {
  width: 162px;
  height: 32px;
  margin-right: 10px;
  float: left
}

.alerted {
  color: #fc7373
}

.alerted::-webkit-input-placeholder {
  color: red
}

.alerted::-moz-placeholder {
  color: red
}

.alerted:-moz-placeholder {
  color: red
}

.alerted:-ms-input-placeholder {
  color: red
}

.offer-form__form-textarea {
  width: 530px;
  height: 45px;
  resize: none;
  padding-top: 6px;
  margin-top: 10px
}

.offer-form__submit-container {
  position: relative;
  text-align: center;
  padding: 10px 0
}

.submit-container__processing-message {
  position: relative;
  overflow: hidden;
  font-weight: bold
}

.offer-form__phone-wrapper {
  position: relative;
  float: left
}

.phone-wrapper__phone-dropdown-list {
  position: absolute;
  width: 29px;
  height: 30px;
  right: 10px;
  margin-right: 7px;
  margin-top: 1px;
  top: 1px;
  cursor: pointer
}

.phone-wrapper__phone-dropdown-list.dropped {
  width: calc(100% - 12px);
  margin-right: 0;
  margin-top: 0;
  border-radius: 5px;
  height: auto;
  z-index: 100;
  border: 1px solid #cecfcf;
  overflow: hidden
}

.phone-dropdown-list__dropdown-list-item {
  position: relative;
  width: calc(100% - 13px);
  padding: 0 6px;
  height: 30px;
  line-height: 30px;
  background: #f8f9f9;
  cursor: pointer
}

.phone-dropdown-list__dropdown-list-item:after {
  position: absolute;
  content: "";
  width: 29px;
  height: 20px;
  right: 5px;
  top: 5px
}

.phone-wrapper__phone-dropdown-list.ru,.phone-dropdown-list__dropdown-list-item.ru:after {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAUCAIAAAD+/qGQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAFrSURBVHjarJQxbhRBFESrfv/unh2b8a7sUxASgA+AE+AmvgV3cMoVNgB8hnEAAQFnsIRkbeDAgp1fBGvJK4Qw8nSl3Xpdet39uV6v+76XJAmzQ5LkOI7e9/3rs7NpmgRwHnRHSCkB8IhQ4OZmg0Y5OT6OCAdghpK9FdcMAHwnJefciksSgCcCQNcVtLg3kAAS4ctqANyb9QWwrOYvP1yf//h2cfkdxgYPInT+5vnFx2tHtSHno9WznHZmZmCFX5OGnFHNQdJs6Go2a8CNoBlIB6Swg9J54nwN20kKA+SwFCyLbpHYwO8kBQssOepwW1fTQFmDvhG6rUvUgV8yXrx99/NqhBHzBYfKq9Ovnz/5psNd6e9WJ/cfcGYiovSbDj4RVHBRW3GpmAgX4NqWWvSYBP7T7P0eybXVbu4kKC+q/o/4V3rsrSYIgNMANz882Dv10Vp/Jj0cR7jRwPeHOPW9Waan9uXDRBu3+D0APVZ+VvO7rt8AAAAASUVORK5CYII=') no-repeat center center
}

.phone-wrapper__phone-dropdown-list.by,.phone-dropdown-list__dropdown-list-item.by:after {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAUCAIAAAD+/qGQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAJaSURBVHjanJO7a1RBFIe/c2ZudvduNmaVoKCV2qigoKIidgYfoI34RFAsFPHRWCv4HyhYSxobH4gYg0QtbATxhWglIb4KTQQxMW72vmbGYgXrew+/+uOb35wj9wZoFgQBBQFPyCEQhAojIIbngo0zhr935vcfMmtWuYlJ932qNf6Ad88wFinP9gFr2L3DhgK6nbwzV3z5HP78dp05lybm4F4aFtVy0BDIc0buBoclQFHosqW1E8eA9P6Yd87UII4xpqSsJ03I0iA9rnO1o0ekGcvCdt/OYZzDKtaU5wrOknYR/r00Gx3zM7Ph12w6OgaCUDEKSRd6XJH66ZPprTvFu/f1/fsQQRWVKjFKmiBYBKz9feR46Ivy1291oNV/+yZGMIKW3AcRvJDN//d1kx/6Nm2o7d7hpn9AqCiriipJzxdwbvDhmK5cgfe1QwdwHpF/KTuqZMl/3+6Va/n44+LV686ly9XL7SmnXcBKANX00WOfdLXdLl6+UVWUiiOQJAq27iFz8bmzyfg4aVY/ethkjlQRrXJv3jCT9CfY8xd4mxSNM6fMhrXRls3iAvNOrs63okjFlOV63MbFM/kgtpaBI70+Yvbsct+mipEb8YWLS3zUziNLOW4geMxgmna7vX5FdGhIF7UJRNu2Ak0bLaBWvt3gCRqc+t5dqLjJjzrXAfIXr6LN2xu1qO6jCt/mJaAZYBVQy6ev7slTNzEh0z+xNo5NI6vADYAaF8BaBdOMV69ntsAuYHE/UbPVrMdRnUpgEwUUWbeXgeUUSlAQxGMLLNRd1RVWpj/zdwBAc/ycgk0M8AAAAABJRU5ErkJggg==') no-repeat center center
}

.phone-wrapper__phone-dropdown-list.kz,.phone-dropdown-list__dropdown-list-item.kz:after {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAUCAIAAAD+/qGQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAPwSURBVHjapJRfiFVVFMa/b+19zrl3/jSS1YSOM9NQijMUWNdBMVBI0Mx8MOiPL9FD0EPZmxlG4UOBUIEPmT5EUjAlCT1UEBiE9uDYWOiQd2ZAxnRG8x/a6HTPOfecvVcP52rTlAS2WRv2y/749rd+e9G8sdOFJXhAARD0+J9LKGPD1oWlr55/OskldiUCTTYJJBfobQgq4BXGmPV7YeH1et1t2j+sr60EwXcPIajNdl08pVHaKP5TWOF134Z+eLUgUudhw8Pji4SELQVh3c7QVSD3JvchXFAKp5L6HEguJguYkzpT3KumzqfegbAAas5DuLxnDASEljQyU9Tmvun1hz7e1H2gLZy24j4dX/PB2MZztbtCyWYmRoUo4twDsAAS72Bk8NRiISGkIdnw4bxJfdOWBz9555E9EMADxJaHB+5vPfvU9+8ZMy2S84Y0laKIvQMgAGqZQrjsvpH+7iqEvFEqkjFcNe+nHSt2nb7WjhxwgMP16aaNnQf3PvpmqpFSOOMKDWu5Fn6ZOAfB4K+LhQRBgoRXOrW5izYv/AI5ulovwDeiaQ1qMFh971BzkGY+IDNDX3RWiCT3AAVA7D1ElnWN9HdVYUghRByDzpaL7c2Xu1t+i+NoFnjTSbk1qEU2e67ruwyRiinMiuGNHIjYOwh/PNM7dKYPLMKV1Ec7l7x/+skNA2fWlqP0n7QOTy18q++jvau2pz4ChCRJoSTeg7AEUqcQ9HdWAUAgAgJKs6brMIi54TUQ1+rNd4R/QAFiqt7SVp6+kN65+YHPkQM0IhCCgAcS54tAkHgHcmii9+hEwy+FgfHbfn4ZRF/b+I5fXiiZFAIIQERSr17tuVpvQ4TRK91GPHnzHhPvWfAQO4WRpR3VyoITEKFQBCWb7Rp/FjFO1eYvmTP62eS6kd978txUp3oGJtd9e2HF/KaLaRy+cnxrKciMQcGDGImdR8OvehBDE71HJ3pJJSHUQFyGYN3hXZv7Bg5cXN4a1I5NLXp1eOvZuH31PYOnah1L2kYOXqocvNwfmUyoBUVCTfRvfrl0QbXSUaVp8GiMlm32w5VK+5eH5pau75t8vKN8fl7T5RePbf/6/MrH7j6y/9zaJ4582BIm1ugMhCV2ChR9U0fK0bN9BG4mBdIAZZPlGr598qXO8rnmMN1W2bOtsgcZDkyu2D32TEuQBOIFBbuNnaojYAnGXsWgMv8EABFY44oPQKgAli6S+tWsbf3g7lmole1s/gjETglaEHV1pQAnL3UCiAJHa249Yv9jLhsg1YJfMhW0Rm7pkW8ItESpSHCLqQ3qX+d/X2QqJGn96PEJQBWqBDANhfD2dQGQfvT4nwMAl/S4rdzPXxYAAAAASUVORK5CYII=') no-repeat center center
}

.phone-wrapper__phone-dropdown-list.uz,.phone-dropdown-list__dropdown-list-item.uz:after {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAUCAIAAAD+/qGQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAJpSURBVHjarJPPS1RRFMe/594z777xvRlHxR+U5Ma2LcwQLQKXpYmbFu0zbNGiv0Bs56pFQYbQImYbxPSDdm4q/EXUSBBBFNQiMvvxFJ15M/eeFmolzqiLd7h8udzD/dzDud9DmMrDBBABBP+Cdjb/H+7N1kwS3i4wTPBodCC2ruLEOmFFRitFIMAB1knsBIBACATAU6QVqVpAAZyAFQ0DDJGSxcXC0oXjRwpDPTcWP0wsvFdKAXAisE6unaebj+X68LbefkpaKapRsog4Jw/OnYQIA2ItkGooDPVMLn6cevW5LR1qoqpI7MQ68aZnTTpM3Zk16dBMz+b80NdK1eqEE5Stc44AUQCqokLPB/Dm+2aTn84aP/CMz+bX5bM+e/H4YOCZytXBwDPl8cGM5++3jG+hADC0qhJnTQCg/2jL6x82xcqKeEq68sXAhMfyxcyOduWLgdfg1alXBGylAoZWDD9cM42ca7u0uH7/VLvKtd4qrnwabu8sfP0y0rFXu598U5qIan+csy4yjfBDioDM5Bjuzmw7QO3+YNqtWx5T9U1mgStjaxMz9Gxu7kxf38/1Tdm6R/Vdu+POfdwrIk1h+vn8PEsU6bhiKiUkFDpmiSKGtVqRp3RiXEWwlqE1M5uGtIgcMKAHhxARM0NrRjZHgJ9KIcHI5ug3kB09jaWXIEqAKILegejhC26919/b2V1cqRKpJLDuRGv30ojj5pJkbLlDk0qiXieUseXmkrC2INoIUkiiCxCAaENbMEA2Vfb8Q2DpICSwRQOIARJT9nViXOEyQFxaXn2HFpFDEaX+A+T+DnpcWl79MwA9Zf27/Tg8oAAAAABJRU5ErkJggg==') no-repeat center center
}

.phone-wrapper__phone-dropdown-list.md,.phone-dropdown-list__dropdown-list-item.md:after {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAUCAIAAAD+/qGQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAL4SURBVHjadJVNiBxFFMd/773qnsn2ZLJqNhIUNytCZFeQKOIlfgQP5hJEBBVZBI+it+DJi+eIN8HbIqgXJScvEVEvogE/CLoxKhqMhhxcY3DX6Znu6u7yMDNrd89sUbd69at//eu9V8IjbxMSQqA1KiXbH158nAyq5pJCj5+eR0GYMz4BR0g+PHO8qIJKI6gMdmOUcOJd8oRK29yYo4sLJKBNQVUgcjz8mCOEnWHx3KvncYJKQ++g/8IH6wxm9Brsh2d79EODGwI+552zgAOGPtBxxIrUuUbRwYGBTC8cpnodLCQkFVYXW5EpeQY4hKGvMMO0qddwDkDABAsIlEI1PiYQGZE0uUJwZMPx0aSjgClmqLangxi64Ys3F8+8ti/bUTqBTsDAQOfNfModjPXOQk2J+Hij+8dm/MDTA73WLQPff9Y9t5EQgxNsZjolG019yMsJSJqvbopj83zsq3L7br+86n+7ZL9s2q8X45M2mLLqO4SgY70OJPWggjQTTQQVDGfVahQfuhGvPbMzutDb3nGXrcB29da2BK1xhYEPe+iV9y/cUiVF3M8XHh1Q0D32T/lDnwAFOMVCkwtBxj4okPow31/RE4eu/31s8Zvkjte/fJIDvPHjE9/2lleO5gSQeY9mgs8m+Zv6apJkLb1iS7fSPWL33/7zqTsvUfLy8XNnrx4+slJMEG0fQAQ/3H03EJ3xVxFDyD5Pfy9X/NqfxW3p4lbnwe+Krw5H94nfg6tTLgw87aKY6FVK1te2D16RT6+WVz7qLd/jH7qZ1XuFcpxVgja5ultvkBaghtLmqlFx11MpeRa9ta/00uuz9NK1pThQ8n9pNPoO+FHT33bLM8wmoRGnTg9Pbo2igwEPlUDAgTX1IiiUU3/TgpkkMyQmihjCv1CVCJHC9WnfMbAcUbTVhOs+eFBrHAuIZ2GLAsqZPgkUcFM8KZ96nxSp6S2NiDZ3PFb27Oskr7SDJ6secPz1dXaxtVIL3Vjf6x+6/N60xOb9Q/8NALZrH/HHVw1rAAAAAElFTkSuQmCC') no-repeat center center
}

.phone-wrapper__phone-dropdown-list.ua,.phone-dropdown-list__dropdown-list-item.ua:after {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAUCAIAAAD+/qGQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAEdSURBVHjarNO/TQNxDMXxr32+yxUJICJBh5SSkhVShgqJDRgAiUHYA4mWlJFgCTq2yCn/zo8iyQT38wAfPT3bxusPMSYTUWAM3Pn7DmK8fHs49BjYMFMgiIrFO0Fqv+d52deu4e4+7XNRkQqQxE2rkePD4BTblAQoEILrxhvXcHeXJkAEYOJqZOH4sB4SDmkm4OgaVzXhBfZ2SMzOrovL2qoSbp/m57yVOxc1bmbDYIkU7kAV9NOtaJrTUQ/MC2wF/TQ0u2Myf7pdoRKwwWSu2So2SUtLUOyPaTdJ9IDvSrq+6yEQVOuSbrU+/QVVR13Q7U73i3dFezi6grqjgSzhOtTduYco2kMc8zrQ0VJuOhz7eOHxHrJgv3z98j8Agq5xwnHG+uoAAAAASUVORK5CYII=') no-repeat center center
}

.phone-wrapper__phone-dropdown-list.ot,.phone-dropdown-list__dropdown-list-item.ot:after {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAUCAIAAAD+/qGQAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAADgSURBVHjatJSxDcQwCEUBIU+RMkukcJ/V0mchKztkiGyAY8wVRJfrzdHYNE/4+39w27acs5mZGQwXIiLicRycc16WBaKLfczWWtS8zGxmDAC9d1WN4hIRADAAqKqqRinwcs2s9x7F9Xe/3CgdHq7fYrmI+DduSskt4f2gsk5IKXEpZZ7n67qi/m2aplIKiwgRtdY8goPzmhkRiQh7U2sdhP6in7wBgIhEcZ/94I2rMc79+uqdl4jG9f3mlt10tdYorkPYj/u+A/V98kZE67pG+df9yiKy73vgPiOi8zw/AwAFnrqcJ6+5qAAAAABJRU5ErkJggg==') no-repeat center center
}

.card-page-tabs__card-tabs-container.into-claim {
  display: none
}

.search-page__filters-block {
  position: relative;
  background: #e5e8e9;
  background-image: -moz-linear-gradient(top,#bbc3c5,#e5e8e9);
  background-image: -webkit-linear-gradient(top,#bbc3c5,#e5e8e9);
  background-image: -o-linear-gradient(top,#bbc3c5,#e5e8e9);
  background-image: -ms-linear-gradient(top,#bbc3c5,#e5e8e9);
  background-image: linear-gradient(to top,#bbc3c5,#e5e8e9);
  padding: 0 0 10px 10px;
  clear: both
}

.filters-block__extra-filters-container {
  display: none
}

.filters-block__extra-checks-container {
  position: relative;
  overflow: hidden;
  display: none
}

.filters-block__extra-filters-container.visible,.filters-block__extra-checks-container.visible {
  display: block
}

.filters-block__extra-checks-container>.filters-block__check-item {
  padding: 10px 0 5px 0
}

.filters-block__buttons-container {
  position: relative;
  overflow: hidden;
  padding: 10px 0
}

.filters-block__buttons-container>.filters-block__button-item {
  display: inline-block;
  padding: 5px 0 !important;
  width: calc(25% - 10px);
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #aaa
}

.filters-block__button-item.selected {
  background: #ccc;
  border: 1px solid #aaa
}

.search-page__lost-car-message {
  color: #3a3f45;
  font-weight: bold;
  padding: 10px 0 20px 0
}

.search-page__nocars-block {
  position: relative;
  padding: 10px
}

.search-page__nocars-block>a {
  color: #004b90;
  font-weight: bold;
  text-decoration: underline
}

.search-page__nocars-block>a:hover {
  color: #de7607
}

.filters-block__filters-container {
  position: relative;
  padding-bottom: 10px
}

.filters-block__checks-container {
  position: relative;
  height: 25px
}

.filters-block__inputs-container {
  position: relative;
  padding-bottom: 10px
}

.filters-block__filter-item,.filters-block__input-item {
  position: relative;
  width: 25%;
  padding-top: 5px;
  height: 50px;
  display: inline-block;
  line-height: 25px
}

.filters-block__check-item {
  position: relative;
  cursor: pointer;
  float: left;
  min-width: 25%
}

.filters-block__date-item{
  display: none
}

.filters-block__input-item>input {
  display: block;
  position: relative;
  outline: 0;
  padding: 4px 5px;
  border-left: 1px solid #a3a6a8;
  border-right: 1px solid #a3a6a8;
  border-top: 1px solid #848788;
  border-bottom: 1px solid #bdbdbd;
  border-radius: 3px;
  color: #667078;
  font-size: 12px;
  height: 16px;
  width: calc(100% - 22px);
  -webkit-box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.2);
  -moz-box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.2);
  box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.2)
}

.filters-block__input-item>div {
  position: absolute;
  width: 16px;
  height: 16px;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAACESURBVHjahM8hDsJAAETRV7IkKBRX4QRIFLJmNwjquU4RzWKQnIAT9DaoiiaLYGswjJ7M/9PEGK/YI+acZ0gpBdwxBhxwxJRS6nzTo8U24IQbztjUQouMS1NKWSb7WoIBXc55XvmTJsa4roiExy8i4FklByySU8XtAl54L8x6s6vC42cAZ+4mMRq8yEsAAAAASUVORK5CYII=') no-repeat 5px 4px;
  right: 16px;
  bottom: 4px;
  cursor: pointer;
  display: none;
  opacity: .7
}

.filters-block__input-item:hover>input:not([value=""])~div {
  display: block
}

.filters-block__input-item:hover>input:not([value=""])~div:hover {
  opacity: 1
}

.filters-block__info-tools {
  position: relative;
  width: calc(100% - 10px);
  overflow: hidden
}

.filters-block__info-tools>span:first-of-type {
  float: left;
  color: #667078
}

.filters-block__info-tools>span:nth-of-type(2),.filters-block__info-tools>span:nth-of-type(3) {
  float: right;
  color: #667078;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  padding-left: 10px
}

.filters-block__info-tools>span:nth-of-type(2):hover,.filters-block__info-tools>span:nth-of-type(3):hover {
  color: #3a3f44
}

.results-block__results-container {
  position: relative;
  margin: 0 auto;
  clear: both;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  margin-top: 15px
}

.results-container__results-title-wrapper {
  position: relative;
  width: 105%;
  left: -1%;
  height: 50px
}

.results-container__results-title-wrapper:after {
  position: absolute;
  content: '';
  width: 104%;
  height: 20px;
  top: 30px;
  left: 0;
  background: -webkit-gradient(linear,left top,left bottom,from(rgba(255,255,255,0)),to(rgba(255,255,255,1)),color-stop(1,#fff));
  background: -moz-linear-gradient(rgba(255,255,255,0) 0,rgba(255,255,255,1) 85%);
  background: linear-gradient(to bottom,rgba(255,255,255,0),rgba(255,255,255,1))
}

.results-title-wrapper__title-item {
  position: relative;
  height: 31px;
  width: 134px;
  border-right: 1px solid #a0a8ac;
  border-left: 1px solid #e9ebec;
  border-bottom: 1px solid #bec2c5;
  background: #e5e8e9;
  background-image: -moz-linear-gradient(top,#c5cbce,#e5e8e9);
  background-image: -webkit-linear-gradient(top,#c5cbce,#e5e8e9);
  background-image: -o-linear-gradient(top,#c5cbce,#e5e8e9);
  background-image: -ms-linear-gradient(top,#c5cbce,#e5e8e9);
  background-image: linear-gradient(to top,#c5cbce,#e5e8e9);
  float: left;
  line-height: 31px;
  cursor: pointer;
  text-align: center;
  color: #667078;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2)
}

.results-title-wrapper__title-item .sorted-by {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 12px;
  top: 2px;
  margin-left: 5px;
  background: url(/images/default_gui.png) 0 -390px;
  opacity: .8
}

.results-title-wrapper__title-item .sorted-by.asc {
  background: url(/images/default_gui.png) 0 -435px
}

.results-title-wrapper__title-item .sorted-by.desc {
  background: url(/images/default_gui.png) 0 -412px
}

.results-title-wrapper__title-item.selected {
  color: #3a3f44;
  background: #a6a8a9;
  background-image: -moz-linear-gradient(top,#cfd4d6,#a6a8a9);
  background-image: -webkit-linear-gradient(top,#cfd4d6,#a6a8a9);
  background-image: -o-linear-gradient(top,#cfd4d6,#a6a8a9);
  background-image: -ms-linear-gradient(top,#cfd4d6,#a6a8a9);
  background-image: linear-gradient(to top,#cfd4d6,#a6a8a9)
}

.results-title-wrapper__title-item:after {
  position: absolute;
  content: '';
  height: 17px;
  top: 31px;
  left: -2px;
  border-right: 1px solid #afb4b6
}

.results-title-wrapper__title-item span:first-child {
  border-bottom: 1px dotted
}

.results-title-wrapper__title-item.i1101 {
  width: 503px
}

.results-title-wrapper__title-item.kpp {
  width: 83px
}

.results-title-wrapper__title-item.engine {
  width: 101px
}

.results-title-wrapper__title-item.price {
  width: 124px
}

.results-title-wrapper__title-item:last-child {
  border-right: 0
}

.results-container__result-item {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 13px;
  padding-bottom: 11px;
  overflow: hidden;
  clear: both
}

.result-item__result-cell {
  display: table-cell;
  vertical-align: top;
  padding: 0 6px;
  float: left
}

.result-item__result-cell.num {
  width: 15px;
  text-align: center
}

.result-item__result-cell.i1101 {
  width: 458px
}

.result-item__result-cell.kpp {
  width: 73px;
  text-align: center;
  font-size: 12px
}

.result-item__result-cell.engine {
  width: 83px;
  padding-left: 14px;
  font-size: 12px
}

.result-item__result-cell.price {
  width: 112px;
  font-size: 16px;
  text-align: center
}

.result-item__result-cell.engine span {
  display: block
}

.result-item__result-cell.price>div:nth-of-type(2) {
  text-decoration: line-through
}

.result-cell__credit-value {
  padding-top: 5px;
  font-size: 12px
}

.result-cell__credit-value>div {
  color: #ef3124;
  font-weight: bold;
  font-size: 16px
}

.result-cell__credit-value i {
  font-style: initial
}

.result-item__result-cell.price>span.by-request {
  font-size: 12px
}

.result-item__result-cell.touch,.result-item__callto-button {
  display: none
}

.result-item__callto-button {
  width: 40px;
  height: 40px;
  margin: 40px 12px 0 !important;
  position: relative !important;
  top: 0 !important;
  background: url(/images/callto-button.png) no-repeat center center /contain
}

.result-cell__result-image-block {
  position: relative;
  float: left;
  width: 160px;
  line-height: 16px;
  font-size: 12px;
  padding-right: 5%
}

.result-image-block__sale-label {
  position: absolute;
  top: 0;
  right: 10px;
  color: #5f3a16;
  border-radius: 12px;
  background-clip: padding-box;
  background-color: #f8e514;
  background-image: -moz-linear-gradient(center bottom,rgba(168,85,11,0.56) 0,rgba(248,229,20,0) 100%);
  overflow: hidden;
  z-index: 1;
  font-size: 14px;
  font-weight: bold;
  padding: 0 5px;
  text-align: center;
  line-height: 24px;
  text-shadow: 0 1px 0 rgba(255,255,255,0.7)
}

.result-image-block__custom-search-sale-label {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 10
}

.result-image-block__custom-card-sale-label {
  left: 10px
}

.result-cell__result-image {
  display: block;
  position: relative;
  width: 170px;
  height: 120px;
  max-height: 120px;
  margin-bottom: 5px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat
}

.result-cell__result-descr {
  position: relative;
  float: left;
  width: 245px
}

.result-descr__location {
  border-top: 1px solid #b5bac1;
  margin-top: 7px;
  padding-top: 4px;
  font-size: 11px;
  color: #667078;
  line-height: 15px
}

.result-descr__result-interior,.result-descr__result-options {
  line-height: 14px
}

.result-descr__result-extra {
  font-size: 11px;
  font-weight: bold;
  line-height: 14px;
  padding-top: 5px;
  width: 468px
}

.result-descr__result-interior span,.result-descr__result-options span {
  white-space: nowrap;
  display: inline-block;
  position: relative;
  font-size: 11px;
  color: #667078;
  padding-right: 9px
}

.result-descr__result-options span:after {
  position: absolute;
  content: '';
  height: 11px;
  top: 2px;
  right: 4px;
  border-right: 1px solid #b5bac1
}

.result-descr__result-options span:last-child:after {
  display: none
}

.result-descr__result-title {
  text-decoration: underline;
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #004b90;
  outline: medium none !important;
  cursor: pointer
}

.result-descr__result-title:hover {
  color: #de7607
}

.result-descr__result-title>.result-title__result-equipment {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.result-descr__result-interior {
  padding-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis
}

.results-block__pages-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px
}

.pages-container__page-item {
  position: relative;
  display: inline-block;
  border-radius: 3px;
  background: #e3e6e7;
  background-image: -moz-linear-gradient(top,#e3e6e7,#bcc3c6);
  background-image: -webkit-linear-gradient(top,#e3e6e7,#bcc3c6);
  background-image: -o-linear-gradient(top,#e3e6e7,#bcc3c6);
  background-image: -ms-linear-gradient(top,#e3e6e7,#bcc3c6);
  background-image: linear-gradient(to top,#e3e6e7,#bcc3c6);
  text-shadow: 0 1px 0 rgba(255,255,255,0.7);
  box-shadow: 0 1px 2px rgba(0,0,0,0.5),0 -1px 0 rgba(255,255,255,0.2) inset,0 0 0 1px rgba(255,255,255,0.14) inset;
  font-size: 14px;
  margin-right: 10px;
  padding: 1px 6px;
  color: #667078;
  cursor: pointer
}

.pages-container__page-item:hover {
  color: #de7607
}

.pages-container__page-item.selected {
  padding: 2px 7px !important;
  border-radius: 5px;
  box-shadow: 0 1px 0 rgba(255,255,255,0.35),0 1px 4px rgba(74,41,10,0.59) inset,0 0 8px rgba(102,112,120,0.09) inset;
  background: #e0bc0f;
  background-image: -moz-linear-gradient(top,#f6e213,#d09d0a);
  background-image: -webkit-linear-gradient(top,#f6e213,#d09d0a);
  background-image: -o-linear-gradient(top,#f6e213,#d09d0a);
  background-image: -ms-linear-gradient(top,#f6e213,#d09d0a);
  background-image: linear-gradient(to top,#f6e213,#d09d0a);
  color: #603913
}

a.pages-container__simple-link {
  font-size: 12px;
  color: #004b90;
  outline: 0;
  cursor: pointer;
  position: relative
}

a.pages-container__simple-link:after {
  position: absolute;
  font-family: ilsaRegular;
  font-size: 10px;
  width: 22px;
  text-align: center
}

a.pages-container__simple-link:hover {
  color: #de7607
}

a.pages-container__simple-link.next {
  margin-left: 5px
}

a.pages-container__simple-link.prev {
  margin-right: 15px
}

a.pages-container__simple-link.next:after {
  content: 'A';
  right: -22px;
  bottom: -2px
}

a.pages-container__simple-link.prev:after {
  content: 'B';
  left: -25px;
  bottom: -2px
}

a.default-link-under {
  color: #004b90;
  text-decoration: underline;
  font-weight: normal
}

a.default-link-under:hover {
  color: #de7607;
  text-decoration: underline
}

@font-face {
  font-family: 'ilsaRegular';
  src: url('/fonts/ilsaregular.woff') format('woff'),url('/fonts/ilsaregular.ttf') format('truetype'),url('/fonts/ilsaregular.svg#ilsaRegular') format('svg');
  font-weight: normal !important;
  font-style: normal;
  text-decoration:none}

body {
  padding: 0;
  margin: 0;
  background: white;
  color: #3a3f45;
  font-size: 12px;
  font: normal 13px/20px Arial,"Helvetica Neue",Helvetica,sans-serif;
  font-weight: normal
}

html,button,input,select,textarea {
  font-family: sans-serif;
  outline: 0
}

textarea {
  overflow: auto
}

input[type="checkbox"] {
  cursor: pointer
}

h2 {
  font-size: 20px;
  font-weight: normal;
  color: #3a3f45
}

h3 {
  font-size: 14px;
  font-weight: normal;
  margin-top: 0;
  color: #3a3f45
}

form {
  margin: 0;
  padding: 0;
  display: block
}

a {
  text-decoration: none;
  outline: 0
}

label {
  cursor: pointer
}

#search {
  position: relative;
  width: 810px;
  margin: 0 auto;
  -webkit-touch-callout: none
}

#search.dark {
  background-color: #f1f1f1
}

.rouble {
  position: relative;
  display: inline-block
}

.rouble:after {
  position: absolute;
  font-family: ilsaRegular;
  content: 'P';
  left: 5px;
  bottom: -5px
}

.rouble.to-result-cell {
  font-size: 13px
}

.rouble.to-auto-card {
  font-size: 16px
}

.button-green {
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: white;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  border: 1px solid #107801;
  background-color: #35af38;
  background: -moz-linear-gradient(top,rgba(96,255,102,0.67) 0,rgba(96,255,102,0.94) 9%,rgba(96,255,102,0.93) 13%,rgba(94,251,100,0.92) 16%,rgba(88,240,93,0.9) 22%,rgba(72,210,76,0.87) 34%,rgba(60,190,64,0.83) 47%,rgba(54,177,57,0.81) 56%,rgba(48,167,51,0.77) 69%,rgba(48,168,51,0.75) 75%,rgba(55,180,58,0.74) 81%,rgba(64,196,68,0.72) 88%,rgba(66,200,70,0.71) 91%,rgba(66,200,70,0.7) 94%,rgba(66,200,70,0.69) 100%);
  background: -webkit-gradient(linear,left top,left bottom,color-stop(0,rgba(96,255,102,0.67)),color-stop(9%,rgba(96,255,102,0.94)),color-stop(13%,rgba(96,255,102,0.93)),color-stop(16%,rgba(94,251,100,0.92)),color-stop(22%,rgba(88,240,93,0.9)),color-stop(34%,rgba(72,210,76,0.87)),color-stop(47%,rgba(60,190,64,0.83)),color-stop(56%,rgba(54,177,57,0.81)),color-stop(69%,rgba(48,167,51,0.77)),color-stop(75%,rgba(48,168,51,0.75)),color-stop(81%,rgba(55,180,58,0.74)),color-stop(88%,rgba(64,196,68,0.72)),color-stop(91%,rgba(66,200,70,0.71)),color-stop(94%,rgba(66,200,70,0.7)),color-stop(100%,rgba(66,200,70,0.69)));
  background: -webkit-linear-gradient(top,rgba(96,255,102,0.67) 0,rgba(96,255,102,0.94) 9%,rgba(96,255,102,0.93) 13%,rgba(94,251,100,0.92) 16%,rgba(88,240,93,0.9) 22%,rgba(72,210,76,0.87) 34%,rgba(60,190,64,0.83) 47%,rgba(54,177,57,0.81) 56%,rgba(48,167,51,0.77) 69%,rgba(48,168,51,0.75) 75%,rgba(55,180,58,0.74) 81%,rgba(64,196,68,0.72) 88%,rgba(66,200,70,0.71) 91%,rgba(66,200,70,0.7) 94%,rgba(66,200,70,0.69) 100%);
  background: -o-linear-gradient(top,rgba(96,255,102,0.67) 0,rgba(96,255,102,0.94) 9%,rgba(96,255,102,0.93) 13%,rgba(94,251,100,0.92) 16%,rgba(88,240,93,0.9) 22%,rgba(72,210,76,0.87) 34%,rgba(60,190,64,0.83) 47%,rgba(54,177,57,0.81) 56%,rgba(48,167,51,0.77) 69%,rgba(48,168,51,0.75) 75%,rgba(55,180,58,0.74) 81%,rgba(64,196,68,0.72) 88%,rgba(66,200,70,0.71) 91%,rgba(66,200,70,0.7) 94%,rgba(66,200,70,0.69) 100%);
  background: -ms-linear-gradient(top,rgba(96,255,102,0.67) 0,rgba(96,255,102,0.94) 9%,rgba(96,255,102,0.93) 13%,rgba(94,251,100,0.92) 16%,rgba(88,240,93,0.9) 22%,rgba(72,210,76,0.87) 34%,rgba(60,190,64,0.83) 47%,rgba(54,177,57,0.81) 56%,rgba(48,167,51,0.77) 69%,rgba(48,168,51,0.75) 75%,rgba(55,180,58,0.74) 81%,rgba(64,196,68,0.72) 88%,rgba(66,200,70,0.71) 91%,rgba(66,200,70,0.7) 94%,rgba(66,200,70,0.69) 100%);
  background: linear-gradient(to bottom,rgba(96,255,102,0.67) 0,rgba(96,255,102,0.94) 9%,rgba(96,255,102,0.93) 13%,rgba(94,251,100,0.92) 16%,rgba(88,240,93,0.9) 22%,rgba(72,210,76,0.87) 34%,rgba(60,190,64,0.83) 47%,rgba(54,177,57,0.81) 56%,rgba(48,167,51,0.77) 69%,rgba(48,168,51,0.75) 75%,rgba(55,180,58,0.74) 81%,rgba(64,196,68,0.72) 88%,rgba(66,200,70,0.71) 91%,rgba(66,200,70,0.7) 94%,rgba(66,200,70,0.69) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ab60ff66',endColorstr='#b042c846',GradientType=0);
  box-shadow: 0 1px 2px rgba(0,0,0,0.5),0 -1px 0 rgba(255,255,255,0.2) inset,0 0 0 1px rgba(255,255,255,0.14) inset;
  border-radius: 4px;
  text-align: center;
  text-shadow: 0 -1px 1px #0c6501;
  font-size: 14px;
  padding: 0 20px;
  cursor: pointer
}

.button-green:hover {
  opacity: .6
}

.button-green.to-result-cell {
  margin-top: 10px
}

.button-green.to-auto-card {
  padding: 0 27px;
  height: 40px;
  line-height: 40px;
  float: right
}

.button-green.to-request-form {
  padding: 0 27px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto
}

.close-button {
  position: absolute;
  width: 29px;
  height: 29px;
  background: url("/images/colorbox_close.png") no-repeat scroll 0 0 transparent;
  cursor: pointer;
  z-index: 99;
  top: 10px;
  right: 14px
}

.close-button.to-bottom {
  bottom: 12px;
  top: auto
}

.close-button:hover {
  background-position: 0 -34px
}

.vertical-middle {
  vertical-align: middle
}

.inline {
  display: inline
}

.table {
  display: table
}

.table-cell {
  display: table-cell
}

.inline-block {
  display: inline-block
}

.block {
  display: block
}

.to-left {
  float: left
}

.to-right {
  float: right
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.hidden {
  display: none
}

.text-center {
  text-align: center
}

.m-0-a {
  margin: 0 auto
}

.ml-5 {
  margin-left: 5px
}

.ml-10 {
  margin-left: 10px
}

.ml-20 {
  margin-left: 20px
}

.ml-25 {
  margin-left: 25px
}

.ml-30 {
  margin-left: 30px
}

.mt-20 {
  margin-top: 20px
}

.mt-30 {
  margin-top: 30px
}

.mb-20 {
  margin-bottom: 20px
}

.mb-30 {
  margin-bottom: 30px
}

.pl-5 {
  padding-left: 5px
}

.pl-10 {
  padding-left: 10px
}

.pr-40 {
  padding-right: 40px
}

.pt-5 {
  padding-top: 5px
}

.pt-10 {
  padding-top: 10px
}

.pt-20 {
  padding-top: 20px
}

.pb-5 {
  padding-bottom: 5px
}

.pb-10 {
  padding-bottom: 10px
}

.pb-15 {
  padding-bottom: 15px
}

.pb-20 {
  padding-bottom: 20px
}

.arial {
  font-family: 'Arial'
}

.detailPage_mobilePriceBlock .price{
  font-size: 16px;
  text-align: right;
}

.detailPage_mobilePriceBlock .price.sale span{
  text-decoration: line-through;
}

.actionsContainer {
  margin-top: 20px;
}

.imagesGalleryContainer{
  display: flex;
  align-items: center;
  gap: 16px;
}

.discountPriceBlock {
  min-width: 300px;
  max-width: 300px;
  padding-right: 6px;
  font-size: 16px;
}

.discountPriceBlock .title {
  font-size: 30px;
  font-weight: 700;
}

.discountPriceBlock .pricesContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.discountPriceBlock .pricesContainer .row {
  display: flex;
  justify-content: space-between;
}

.discountPriceBlock .pricesContainer .row .rowTitle {
  padding-right: 5px;
  background: #ffffff;
}

.discountPriceBlock .pricesContainer .row .rowPrice {
  position: relative;
}

.discountPriceBlock .pricesContainer .row .rowPrice.bold {
  font-weight: 700;
}

.discountPriceBlock .pricesContainer .row .rowPrice::before {
  content: '';
  width: 150px;
  position: absolute;
  bottom: 4px;
  right: calc(100% + 5px);
  border-bottom: 1px solid #dbdbdb;
  z-index: -1;
}

.price-block .sale{
  text-decoration: line-through;
}

@media screen and (max-width: 809px){
  .button-green.to-auto-card {
    float: none;
  }

  .discountPriceBlock {
    display: none;
  }
}

@media screen and (min-width: 810px){
  .card-page-top__card-images-accessories-wrapper.withDiscountPrices {
    justify-content: left
  }

  .card-page-top__card-images-accessories-wrapper.withDiscountPrices .card-images-accessories-wrapper__card-images-previews-wrapper {
    width: 458px;
  }

  .card-page-top__card-images-accessories-wrapper.withDiscountPrices .card-images-previews-wrapper__card-images-floater {
    height: 348px;
  }

  .card-page-top__card-images-accessories-wrapper.withDiscountPrices .card-images-previews-wrapper__navigate-button {
    height: 348px;
  }
}



